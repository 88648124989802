import React from "react";

const SalesIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M65.4619 66.5H4.5381C3.9648 66.5 3.5 66.0366 3.5 65.4619V4.5388C3.5 3.9648 3.9648 3.5 4.5381 3.5H12.3557C12.929 3.5 13.3938 3.9648 13.3938 4.5388V56.6041H65.4612C66.0352 56.6041 66.4993 57.0675 66.4993 57.6422V65.4612C66.5 66.0366 66.0352 66.5 65.4619 66.5ZM5.5762 64.4231H64.4231V58.681H12.3564C11.7824 58.681 11.3176 58.2155 11.3176 57.6429V5.5776H5.5762V64.4231Z" />
        <path d="M22.3916 53.571C22.1928 53.571 21.9968 53.5136 21.826 53.403L16.9029 50.2082C16.4213 49.896 16.2855 49.2534 16.597 48.7711L33.0106 23.4808C33.3081 23.0237 33.9066 22.8746 34.384 23.1371L44.0097 28.4571L52.9109 15.1179L47.2661 11.5108C46.9308 11.2952 46.7467 10.9081 46.7943 10.5119C46.8419 10.1164 47.1114 9.78249 47.488 9.65229L65.1245 3.59029C65.4423 3.48109 65.793 3.53219 66.0653 3.72679C66.3383 3.92209 66.5 4.23709 66.5 4.57239V21.8806C66.5 22.2593 66.2942 22.6079 65.9624 22.7906C65.6299 22.9733 65.2253 22.96 64.9054 22.7577L59.6106 19.3963L47.0918 38.1521C46.7915 38.6008 46.1986 38.7457 45.7261 38.4846L36.1732 33.2059L23.2631 53.0978C23.1133 53.3288 22.8774 53.4905 22.6079 53.5479C22.5365 53.5633 22.4637 53.571 22.3916 53.571ZM18.9035 49.0315L22.0864 51.0951L34.9566 31.2634C35.2534 30.8063 35.8533 30.6565 36.33 30.9197L45.8941 36.204L58.4458 17.3992C58.7587 16.9302 59.3894 16.7972 59.8661 17.0982L64.4231 19.992V6.02699L50.1963 10.9172L54.9213 13.937C55.1565 14.0875 55.321 14.3255 55.3784 14.5985C55.4358 14.8715 55.3805 15.1564 55.2258 15.3881L45.2074 30.4031C44.9085 30.8532 44.3149 30.9974 43.8417 30.7363L34.2265 25.4233L18.9035 49.0315Z" />
    </svg>
);

export default SalesIcon;
