import React from "react";
import DashboardLayout from "../layouts/DashboardLayout";

const DashboardPage = () => {
    return (
        <DashboardLayout>
            <p>Dashboard</p>
        </DashboardLayout>
    );
};

export default DashboardPage;
